import { useEffect } from 'react';

import { toggleDocumentScroll } from 'utils/ui/toggleDocumentScroll';

type Props = {
  show?: boolean;
};

let globalHideTimestamp = 0;

export function useHideDocumentScroll({ show }: Props = {}) {
  useEffect(() => {
    if (show) return undefined;

    const timestamp = new Date().getTime();
    globalHideTimestamp = timestamp;
    toggleDocumentScroll({ show: false });

    return () => {
      if (globalHideTimestamp !== timestamp) return;
      toggleDocumentScroll({ show: true });
    };
  }, [show]);
}
