import { ReactNode, useId } from 'react';
import styled from 'styled-components';

import { H2 } from 'components/Text/H2';
import { useHideDocumentScroll } from 'hooks/useHideDocumentScroll';
import { useIsMounted } from 'hooks/useIsMounted';
import { CloseButton, Dialog, ModalBase } from 'modals/ModalBase';
import { colors, cssBreakpoints } from 'theme/theme';

import { ModalDismissalReason } from './ModalDismissalReason';

const StyledDialog = styled(Dialog)<{
  $maxWidth: 'none' | number | undefined;
  $wide: boolean;
  $noPadding: boolean;
}>`
  background-image: linear-gradient(
    to left,
    ${colors.hoverBlue},
    ${colors.secondarySkyBlue}
  );
  background-size: 100% 7px;
  background-repeat: no-repeat;
  padding: ${(props) => (props.$noPadding ? undefined : '42px 20px 35px')};
  max-width: ${(props) => {
    if (typeof props.$maxWidth === 'undefined') return;

    if (props.$maxWidth === 'none') return 'none';

    return `${props.$maxWidth}px}`;
  }};

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    padding: ${(props) => (props.$noPadding ? undefined : '40px')};
    ${(props) => props.$wide && `min-width: 650px`}
  }
`;

const StyledCloseButton = styled(CloseButton)`
  color: ${colors.mediumContentGrey};
  height: 24px;
  width: 24px;

  &:hover {
    color: ${colors.brandBlue};

    &:disabled {
      &:hover {
        color: ${colors.lightContentGrey};
      }
    }
  }
`;

type Props = {
  children: ReactNode;
  className?: string;
  uncloseable?: boolean;
  isCloseButtonVisible?: boolean;
  image?: ReactNode;
  title?: ReactNode;
  onClose?: (reason?: ModalDismissalReason) => void;
  'data-qa-id'?: string;
  wide?: boolean;
  maxWidth?: 'none' | number;
  noPadding?: boolean;
};

export function Modal({
  children,
  className,
  uncloseable,
  isCloseButtonVisible = true,
  title,
  image,
  onClose,
  wide,
  'data-qa-id': dataQaId,
  maxWidth,
  noPadding,
}: Props) {
  const isMounted = useIsMounted();

  useHideDocumentScroll({
    show: isMounted(),
  });

  const id = useId();

  return (
    <ModalBase className={className} onClose={onClose} aria-labelledby={id}>
      <StyledDialog
        data-qa-id={dataQaId}
        $wide={Boolean(wide)}
        $maxWidth={maxWidth}
        $noPadding={Boolean(noPadding)}
      >
        {onClose && !uncloseable && isCloseButtonVisible && (
          <StyledCloseButton
            onClick={(e: React.MouseEvent) =>
              onClose({ type: 'click-x', originalEvent: e })
            }
            data-qa-id="close-modal-button"
            data-modal-focus="false"
          />
        )}

        {image}

        <div>
          {title && (
            <H2 id={id} styleAs="h3" pb={2}>
              {title}
            </H2>
          )}

          {children}
        </div>
      </StyledDialog>
    </ModalBase>
  );
}
