import { postJson } from 'utils/http/postJson';

import { WebsiteFeedbackPayload } from './types/WebsiteFeedbackPayload';

export const YesNoFeedback = { Yes: 5, No: 0 } as const;

export async function postWebsiteFeedback(
  payload: WebsiteFeedbackPayload,
): Promise<void> {
  if (
    payload.rating !== undefined &&
    (payload.rating < 0 || payload.rating > 5)
  ) {
    throw new Error('Rating must be between 0 and 5 inclusive');
  }

  await postJson('/data/website/feedback', payload);
}
