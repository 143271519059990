import { useCallback, useEffect } from 'react';

// Add more keys here as necessary
//
// Complete list:
// https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
type KeyName = 'Escape' | 'Enter';

export function useOnWindowKeyDown(
  keys: KeyName[],
  callback: ((event: KeyboardEvent) => void) | undefined,
) {
  const keydown = useCallback(
    (event: KeyboardEvent) => {
      if (!callback) return;
      if (keys.includes(event.key as KeyName)) callback(event);
    },
    [callback, keys],
  );

  useEffect(() => {
    const options = { capture: true };
    window.addEventListener('keydown', keydown, options);

    return () => {
      window.removeEventListener('keydown', keydown, options);
    };
  }, [keydown]);
}
