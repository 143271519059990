import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { useActionToast } from 'components/ActionToast/useActionToast';
import { useActionToastPersistence } from 'hooks/useActionToastPersistence';
import {
  YesNoFeedback,
  postWebsiteFeedback,
} from 'modules/cms/api/feedback/postWebsiteFeedback';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { trackDisplayToast } from 'utils/analytics/track/trackDisplayToast';
import { trackEvent } from 'utils/analytics/track/trackEvent';
import { useUser } from 'zustand-stores/userEnvironmentStore';

import { FeedbackModal } from './CmsHelpPageFeedback/FeedbackModal';
import { getPageTypeAndLocale } from './CmsHelpPageFeedback/getPageTypeAndLocale';

type CmsHelpPageFeedbackProps = {
  contentId: string;
  contentTitle: string;
};

export function CmsHelpPageFeedback({
  contentId,
  contentTitle,
}: CmsHelpPageFeedbackProps): JSX.Element | null {
  const { pathname } = useLocation();
  const { showToast, dismissToast } = useActionToast();
  const isLoggedIn = Boolean(useUser().user);
  const { hasDismissedToast, persistDismissedToastId, getDismissal, clear } =
    useActionToastPersistence('help-page');
  const [hasCheckedDates, setHasCheckedDates] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [isPositiveFeedback, setIsPositiveFeedback] = useState(false);

  useEffect(() => {
    setHasCheckedDates(true);
    const ONE_MONTH = 30 * 24 * 60 * 60 * 1000;
    const dismissal = getDismissal('help-page-toast');
    if (!dismissal) return;
    const age = Date.now() - dismissal.dismissedAt;
    if (age > ONE_MONTH) {
      clear();
    }
  }, [clear, getDismissal, hasCheckedDates]);

  const handleFeedbackSubmit = async (comment: string) => {
    const { locale } = getPageTypeAndLocale(pathname);
    await postWebsiteFeedback({
      campaign: `support_${locale}`,
      objectType: 'CMS_PAGE',
      objectId: contentId,
      url: pathname,
      rating: isPositiveFeedback ? YesNoFeedback.Yes : YesNoFeedback.No,
      comment,
    });

    trackEvent('Submitted: Help Article Feedback', {
      response_type: isPositiveFeedback ? 'YES' : 'NO',
      name: contentTitle,
      current_page_url: window.location.href,
    });
  };

  const handleModalClose = (hasSubmittedFeedback: boolean) => {
    if (!hasSubmittedFeedback) {
      handleFeedbackSubmit('');
      trackEvent('Dismissed: Help Article Feedback', {
        response_type: isPositiveFeedback ? 'YES' : 'NO',
        name: contentTitle,
        current_page_url: window.location.href,
      });
    }
    setShowFeedbackModal(false);
  };

  useEffect(() => {
    if (!isLoggedIn || !hasCheckedDates) return;
    const { isHelpPage } = getPageTypeAndLocale(pathname);
    const canDisplay = isHelpPage && !hasDismissedToast('help-page-toast');
    if (!canDisplay) return;

    trackDisplayToast({
      type: 'help_article',
      name: contentTitle,
      current_page_url: window.location.href,
    });

    showToast({
      id: 'help-page-toast',
      headline: 'Was this article helpful?',
      variant: 'info',
      actions: [
        {
          id: 'feedback',
          onFeedback: (result: 'positive' | 'negative') => {
            const isPositive = result === 'positive';

            trackClicked('Action Toast', {
              type: 'help_article',
              name: contentTitle,
              current_page_url: window.location.href,
              button_copy: isPositive ? 'yes' : 'no',
            });

            setIsPositiveFeedback(isPositive);
            setShowFeedbackModal(true);

            trackEvent('Modal View: Help Article Feedback', {
              response_type: isPositive ? 'YES' : 'NO',
              name: contentTitle,
              current_page_url: window.location.href,
            });

            persistDismissedToastId('help-page-toast');
            dismissToast('help-page-toast');
          },
          type: 'feedback',
          positiveLabel: 'Yes',
          negativeLabel: 'No',
        },
      ],
      dismissible: true,
      duration: 15_000,
      onDismiss: () => {
        trackEvent('Dismissed: Action Toast', {
          type: 'help_article',
          name: contentTitle,
          current_page_url: window.location.href,
        });
        persistDismissedToastId('help-page-toast');
      },
    });
  }, [
    pathname,
    showToast,
    dismissToast,
    isLoggedIn,
    contentId,
    hasDismissedToast,
    persistDismissedToastId,
    hasCheckedDates,
    contentTitle,
  ]);

  if (!showFeedbackModal) return null;

  return (
    <FeedbackModal
      isPositive={isPositiveFeedback}
      onSubmit={handleFeedbackSubmit}
      onClose={handleModalClose}
    />
  );
}
