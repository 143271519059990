import { ReactNode, useEffect, useMemo } from 'react';

import { ArticleSearchHeroLoadable } from 'components/ArticleSearchHero/ArticleSearchHeroLoadable';
import { Box } from 'components/Box/Box';
import { ClientOnly } from 'containers/ClientOnly';
import { CmsApiPage, CmsApiPagePage } from 'modules/cms/api/types/CmsApiPage';
import { CmsRecommendedJobsBlockLoadable } from 'modules/cms/blocks/RecommendedJobs/CmsRecommendedJobsBlockLoadable';
import {
  CmsBodyHolder,
  CmsBodyHolderWidth,
} from 'modules/cms/components/BodyHolder/CmsBodyHolder';
import { CmsMobileTableOfContentsLoadable } from 'modules/cms/components/TableOfContents/CmsMobileTableOfContentsLoadable';
import { getCmsApiPageDefaultSearchOrPostSearchType } from 'modules/cms/helpers/getCmsApiPageDefaultSearchOrPostSearchType';
import { CmsHelpPageFeedback } from 'modules/cms/pages/Page/CmsHelpPageFeedback';
import { SearchHeroLoadable } from 'modules/search/components/Hero/SearchHeroLoadable';
import { FixedStaffToolsLoadable } from 'modules/staff/components/FixedStaffTools/FixedStaffToolsLoadable';
import { userDashboardApiEnableProfileFeatures } from 'modules/userDashboard/api//userDashboardApiEnableProfileFeatures';
import { useCurrentUserIsStaff } from 'store/hooks/useCurrentUserIsStaff';
import { sectionPadding } from 'theme/theme';
import { showModal } from 'zustand-stores/modalStore';
import { useUserEnvironment } from 'zustand-stores/userEnvironmentStore';

type Props = {
  content: CmsApiPagePage;
  children: ReactNode;
};

export function CmsPagePageLayout({ content, children }: Props) {
  const isStaff = useCurrentUserIsStaff();
  const { user } = useUserEnvironment();

  const hasBelowBlocks = useMemo(
    () =>
      content.hasRecommendedJobs ||
      (content.tags && content.tags.length > 0) ||
      content.author ||
      content.hasReactions ||
      content.enableComments,
    [
      content.author,
      content.enableComments,
      content.hasReactions,
      content.hasRecommendedJobs,
      content.tags,
    ],
  );

  useEffect(() => {
    if (
      content.enableProfileOnVisit &&
      user?.emailVerified &&
      !user.hasIdealistProfileFeature
    ) {
      userDashboardApiEnableProfileFeatures().then(() => {
        showModal('CREATE_USER_PROFILE', {
          title: getText('Create Your Profile'),
          description: getText(
            'To continue, please review the fields below and create a public profile.',
          ),
        });
      });
    }
  }, [
    content.enableProfileOnVisit,
    user?.emailVerified,
    user?.hasIdealistProfileFeature,
  ]);

  const hero = content.heroSearch?.onlyResults ? undefined : content.heroSearch;
  const heroDefaultSearchOrPostSearchType =
    getCmsApiPageDefaultSearchOrPostSearchType({ content });

  const bottomTableOfContents = content.tableOfContents;

  return (
    <>
      {isStaff && <FixedStaffToolsLoadable content={content} />}

      {bottomTableOfContents && bottomTableOfContents.length > 0 && (
        <ClientOnly>
          <CmsMobileTableOfContentsLoadable
            parents={bottomTableOfContents}
            hasButtonBar={false}
          />
        </ClientOnly>
      )}

      {hero && (
        <div style={{ position: 'relative' }}>
          {heroDefaultSearchOrPostSearchType === 'CMS_PAGE' ? (
            <ArticleSearchHeroLoadable
              title={hero.text}
              image={hero.image}
              imageMobile={hero.imageMobile}
              content={content as CmsApiPage}
            />
          ) : (
            <SearchHeroLoadable
              title={hero.text}
              image={hero.image || undefined}
              imageMobile={hero.imageMobile || undefined}
              defaultSearchType={heroDefaultSearchOrPostSearchType}
            />
          )}
        </div>
      )}

      {children}

      {hasBelowBlocks && (
        <Box flex="1 1 auto" width="100%" m={sectionPadding} mt={70}>
          {content.hasRecommendedJobs && (
            <CmsBodyHolder $width={CmsBodyHolderWidth.NARROW_LAYOUT_WIDE}>
              <CmsRecommendedJobsBlockLoadable
                data={{
                  headline: content.recommendedJobsHeadline || null,
                  layout: 'REGULAR',
                  jobFunction: null,
                  areaOfFocus: null,
                }}
              />
            </CmsBodyHolder>
          )}
        </Box>
      )}

      <CmsHelpPageFeedback
        contentId={content.id}
        contentTitle={content.title}
      />
    </>
  );
}
