import styled from 'styled-components';

import { spacing } from 'theme/theme';

export const FeedbackModalIcon = styled.img`
  width: 72px;
  height: 72px;
  margin: 0 0 ${spacing[16]};
`;

export const FeedbackModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
