type PageTypeAndLocale =
  | {
      isHelpPage: true;
      locale: UserLocale;
    }
  | { isHelpPage: false; locale: null };

export function getPageTypeAndLocale(pathname: string): PageTypeAndLocale {
  if (pathname.startsWith('/en/help')) {
    return {
      isHelpPage: true,
      locale: 'en',
    };
  }

  if (pathname.startsWith('/es/ayuda')) {
    return {
      isHelpPage: true,
      locale: 'es',
    };
  }

  if (pathname.startsWith('/pt/ajuda')) {
    return {
      isHelpPage: true,
      locale: 'pt',
    };
  }

  return { isHelpPage: false, locale: null };
}
